import React, { Component } from 'react'
import eventicon from './media/calendaricon.png';
import eventlocation from './media/location.png';
import eventmask from './media/tilemask.png';

export class Events extends Component {

    constructor(props){
        super(props);
        console.log(props.info);
        this.state = { events: props.info };
    }

    static getDerivedStateFromProps(props, current_state) {
        if (current_state.events !== props.info) {
          return {
            events: props.info,
            
          }
        }

        return null;
    }

    componentDidMount () {
    
       
    }
  
   
    openEventInfo = (code) => {
      var newLocation = "/event/"+code;
      window.location.href = newLocation;
      return;
    }

    render() {
        return (
            <div>
              <br /><br />
            {this.state.events.length != 0 &&
                <div style={{ display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "stretch",
                  flexWrap: 'wrap',
                  flexShrink: 1,}}>
                  {
                        this.state.events.map((event, i) =>
             <div key={i} onClick={() => this.openEventInfo(event.event_code)}  style={{width: '350px', height: '300px', position: 'relative', margin: '10px', cursor: 'pointer' }}>
                        <div style={{width: 250, height: 200, left: 0, top: 0}}>
                 <img src={event.event_image} width="350px" height="200" style={{ objectFit: 'cover', borderRadius: '8px'}} />
                 </div>
                 <div style={{width: 250, height: 150, left: 0, top: 0, position: 'absolute'}}>
                 <img src={eventmask} width="350px" height="200" style={{ borderRadius: '8px'}} />
                         
                 <div style={{width: 350, position: 'absolute',  left: 5, top: 155, margin: '7px',  textAlign: 'left', color: 'rgb(250,250,250)', fontSize: 25,  fontWeight: '800', wordWrap: 'break-word'}}>{event.name}</div>
                 </div>

                 <div style={{width: 330, height: 30, left: 10, marginTop: '10px', textAlign: 'left', color: 'rgb(50,50,50)', fontSize: 14, fontWeight: '400', display: 'flex', alignItems: 'center', alignContent: 'center' }}>
                 <img src={eventlocation} width="25px" height="25px" style={{ verticalAlign: 'center', marginTop: '5px', marginRight: '10px' }}  />{event.event_place}</div>
                 <div style={{width: 330, height: 30, left: 10, marginTop: '10px', textAlign: 'left', color: 'rgb(50,50,50)', fontSize: 14, fontWeight: '400', display: 'flex', alignItems: 'center', alignContent: 'center' }}>
                 <img src={eventicon} width="22px" height="22px" style={{ verticalAlign: 'center', marginTop: '5px',  marginLeft: '3px', marginRight: '10px' }}  />{event.event_date}</div>
                 
                
             </div>
                        )
                 }
                 </div>
            }

            {this.state.events.length == 0 &&
              <div >
               <div  style={{ width: '100%', marginTop: '20px', textAlign: 'center', justifyContent: 'center', alignItems: 'center', height: '20vh', display: 'flex'}}>
                <img src={eventicon} width="40px" height="40px" style={{ objectFit: 'cover', borderRadius: '25px'}} />
                <div style={{ marginLeft: '20px', color: 'rgb(50,50,50)', fontWeight: '600', fontSize: '18px' }}>No events</div>  
                </div> 
                
              </div>
            }

            </div>
        )
    }
}

export default Events
