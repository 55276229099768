import React, { Component } from 'react'
import eventicon from './media/calendaricon.png';
import Drawer from 'react-drag-drawer';
import './Popupstyle.css';

export class TicketList extends Component {

    constructor(props){
        super(props);
        this.state = { ticketList: props.info, selectedSection: 0, showTicketInfo: false, currentTicket: null, currentQuantity: 1 };
    }

    static getDerivedStateFromProps(props, current_state) {
        if (current_state.ticketList !== props.info) {
          return {
            ticketList: props.info,
            
          }
        }

        return null;
    }

    componentDidMount () {
    
       
    }
  

    selectTicket = (index) => {
        var currentTicket = this.state.ticketList[index];
        this.setState({ showTicketInfo: true, currentTicket: currentTicket, currentQuantity: 1 });
        
    }

    closeModals = () => {
        this.setState({ showTicketInfo: false, currentTicket: null });
    }

    addQuantity = () => {
        this.setState({ currentQuantity: this.state.currentQuantity+1});
      }

      removeQuantity = () => {
        if(this.state.currentQuantity>1){
          this.setState({ currentQuantity: this.state.currentQuantity-1});
        }
      }
   
      buyTicket = () => {
        this.props.buyTicket(this.state.currentTicket.id, this.state.currentQuantity);
      }

    render() {
        return (
            <div>
            <div  style={{ alignContent: 'center', alignItems: 'center', marginTop: '60px', display: 'flex', flexDirection: 'column'}}>
            
            {
           this.state.ticketList.map((ticket, i) =>
            <div key={i} onClick={() => this.selectTicket(i)} style={{ cursor: 'pointer', marginTop: '20px', width: '90%', borderRadius: '15px', backgroundColor: 'white', minHeight: '50px'}}>
           
            <div style={{ fontWeight: '600', width: '70%', textAlign: 'left', marginLeft: '15px', marginTop: '10px', fontSize: '16px', color: 'rgb(50,50,50)' }}>{ticket.name}</div>  
            <div style={{ fontWeight: '600', marginLeft: '69%', width: '30%', minHeight: '30px', textAlign: 'right', fontSize: '16px', color: 'rgb(50,50,50)'  }}>{ticket.price}
            {ticket.currency == 'yen' && <span>¥</span>}
            {ticket.currency == 'eur' && <span>€</span>}
            {ticket.currency == 'usd' && <span>$</span>}
            </div> 

            {ticket.description != "" &&
            <div style={{ fontWeight: '400', width: '70%', marginBottom: '10px', marginTop: '-20px', textAlign: 'left', marginLeft: '15px', fontSize: '14px', color: 'rgb(100,100,100)' }}>{ticket.description}</div>  
            }
            </div>
           )
            }
                
            </div> 


            <Drawer open={this.state.showTicketInfo}
                    onRequestClose={this.closeModals}
                    modalElementClass="modal">
            <div>

            {this.state.currentTicket != null &&
            <div style={{ justifyContent: 'center', alignContent: 'center', textAlign: 'center', display: 'flex', flexDirection: 'column' }}>
            <div style={{ cursor: 'pointer', margin: 'auto', marginTop: '20px', width: '90%', borderRadius: '15px', backgroundColor: 'white', minHeight: '50px'}}>
           
           <div style={{ fontWeight: '600', width: '70%', textAlign: 'left', marginLeft: '15px', marginTop: '10px', fontSize: '16px', color: 'rgb(50,50,50)' }}>{this.state.currentTicket.name}</div>  
           <div style={{ fontWeight: '600', marginLeft: '69%', width: '30%', minHeight: '30px', textAlign: 'right', fontSize: '16px', color: 'rgb(50,50,50)'  }}>{this.state.currentTicket.price}
           {this.state.currentTicket.currency == 'yen' && <span>¥</span>}
           {this.state.currentTicket.currency == 'eur' && <span>€</span>}
           {this.state.currentTicket.currency == 'usd' && <span>$</span>}
           </div> 

           {this.state.currentTicket.description != "" &&
           <div style={{ fontWeight: '400', width: '70%', marginBottom: '10px', marginTop: '-20px', textAlign: 'left', marginLeft: '15px', fontSize: '14px', color: 'rgb(100,100,100)' }}>{this.state.currentTicket.description}</div>  
           }
           </div>
           
            <div style={{ width: '100%', marginTop: '10px', height: '1px', backgroundColor: '#F2F1F9' }}></div>
           <div>
           <div>
                <div style={{ marginTop: '25px', width: '100%', textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                <div style={{ userSelect: 'none', color: 'rgb(100,100,100)', fontSize: '34px', cursor: 'pointer'}} onClick={this.removeQuantity}>-</div>
                <div style={{ userSelect: 'none', marginLeft: '30px', marginRight: '30px', color: 'black', fontSize: '35px'}}>{this.state.currentQuantity}</div>
                <div style={{ userSelect: 'none', color: 'rgb(100,100,100)', fontSize: '34px', cursor: 'pointer'}} onClick={this.addQuantity}>+</div>
                </div>
            </div>
            <br/>
            <div style={{ width: '100%', marginTop: '10px', height: '1px', backgroundColor: '#F2F1F9' }}></div>
            <div style={{ display: 'flex', justifyContent: 'space-between', margin: '20px'}}>
            <div>
            <div style={{ fontWeight: '400', width: '30%', margin: 'auto', marginTop: '20px', minHeight: '30px', textAlign: 'center', fontSize: '14px', color: 'rgb(50,50,50)'  }}>Total
            </div> 
            <div style={{ fontWeight: '600', width: '30%', margin: 'auto', marginTop: '0px', minHeight: '30px', textAlign: 'center', fontSize: '20px', color: 'rgb(50,50,50)'  }}>{this.state.currentTicket.price*this.state.currentQuantity}
           {this.state.currentTicket.currency == 'yen' && <span>¥</span>}
           {this.state.currentTicket.currency == 'eur' && <span>€</span>}
           {this.state.currentTicket.currency == 'usd' && <span>$</span>}
           </div> 
           </div>
           <div>
            <br />
           <div onClick={this.buyTicket} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', fontSize: '16px', fontWeight: '600', verticalAlign: 'middle', margin: '7.5px', 
            height: '50px', borderRadius: '8px', color: 'white', backgroundColor: '#007aff', cursor: 'pointer', width: '150px'}}>
          Continue</div>
          </div></div>

          </div>


            </div>
            }
                             
           
            </div>
                 
            </Drawer>

            </div>
        )
    }
}

export default TicketList
