import React, { Component } from 'react'
import eventicon from './media/calendaricon.png';

export class MenuList extends Component {

    constructor(props){
        super(props);
        this.state = { menuList: props.info, selectedSection: 0 };
    }

    static getDerivedStateFromProps(props, current_state) {
        if (current_state.menuList !== props.info) {
          return {
            menuList: props.info,
            
          }
        }

        return null;
    }

    componentDidMount () {
    
       
    }
  

    selectedSection = (index) => {
        this.setState({ selectedSection: index });
        if(index == 0){
            this.props.select(0);
        }else{
            this.props.select(this.state.menuList[index-1].id);
        }
        
    }
   

    render() {
        return (
            <div>
              
              <div  style={{ alignContent: 'center', alignItems: 'center', height: '50px', marginLeft: '50px', marginTop: '10px', display: 'flex'}}>
                
                <div onClick={() => this.selectedSection(0)} style={{ cursor: 'pointer', height: '30px'}}>
                    {this.state.selectedSection == 0 &&
                        <div><div style={{ fontWeight: '600', fontSize: '14px' }}>Events</div>  
                        <div style={{width: '100%', height: 2, marginTop: '8px', backgroundColor: 'rgb(50,50,50)'}}>
                        </div></div>
                    }
                    {this.state.selectedSection != 0 &&
                       <div style={{ }}><div style={{ fontWeight: '600', color: 'rgb(100,100,100)', fontSize: '14px' }}>Events</div>  
                       <div style={{width: '100%', height: 2, marginTop: '8px', backgroundColor: 'rgba(50,50,50,0)'}}>
                       </div></div>
                    }
                    
                </div>

                {
           this.state.menuList.map((menu, i) =>
            <div key={i} onClick={() => this.selectedSection(i+1)} style={{ cursor: 'pointer', marginLeft: '20px', height: '30px'}}>
           {this.state.selectedSection == (i+1) &&
               <div style={{ }}><div style={{ fontWeight: '600', fontSize: '14px' }}>{menu.name}</div>  
               <div style={{width: '100%', height: 2, marginTop: '8px', backgroundColor: 'rgb(50,50,50)'}}>
               </div></div>
           }
           {this.state.selectedSection != (i+1) &&
                <div style={{ }}><div style={{ fontWeight: '600', color: 'rgb(100,100,100)', fontSize: '14px' }}>{menu.name}</div>  
                <div style={{width: '100%', height: 2, marginTop: '8px', backgroundColor: 'rgba(50,50,50,0)'}}>
                </div></div>
           }
           
       </div>
           )
    }
                
                </div> 

            </div>
        )
    }
}

export default MenuList
